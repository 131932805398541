import axios from 'axios'
import { path } from './path'

class Server {
  constructor (http = axios, allError = window.global.$message.warning) {
    this.$http = http
    this.error = function (err, isadd, cb) {
      return function (res) {
        if (err) {
          err(res.data)
        }
        if (isadd || !err) {
          const resData = res.response.data
          const msg = resData.errors && resData.errors.map(function (item) {
            return item.message || item.msg
          }).join(',')
          allError(msg || resData.message || resData.msg || '系统异常，请稍后再试！')
        }
        cb && cb()
      }
    }
  }

  // 提供方法清空所有为空的字段
  static clearAllNull (obj) {
    let o
    if (obj instanceof Array) {
      o = []
      obj.forEach(function (item) {
        o.push(Server.clearAllNull(item))
      })
    } else if (obj instanceof Object) {
      o = Object.assign({}, obj)
      for (const key in o) {
        if (!o[key] && o[key] !== 0) {
          delete o[key]
        } else {
          o[key] = Server.clearAllNull(o[key])
        }
      }
    }
    return o || obj
  }

  // 提供方法清空一层为空的obj字段
  static clearNull (obj) {
    const o = Object.assign({}, obj)
    for (const key in o) {
      if (!o[key] && o[key] !== 0) {
        delete o[key]
      }
    }
    return o
  }

  static clearTrim (obj) {
    const o = Object.assign({}, obj)
    for (const key in o) {
      if (!o[key] && o[key] !== 0) {
        delete o[key]
      } else if (typeof (o[key]) === 'string' || o[key] instanceof String) {
        o[key] = o[key].trim()
      }
    }
    return o
  }

  static $message (self, response) {
    if (!(response && response.headers && response.headers['x-response-by'] && response.headers['x-response-by'] === 'gateway')) {
      self.$message({
        type: 'error',
        message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
          return item.message
        }).join(',')))) || '网络异常，请稍后再试'
      })
    } else if (!response.headers) {
      self.$message(response && response.stack)
    }
  }
}

Server.path = path
export default Server
