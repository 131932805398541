<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;

}

#app {
  height: 100%;
  width: 100%;
  font-family: PingFangSC, PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,li,ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.default-dialog-form-style {
  width: 700px;
  min-height: 170px;
  max-height: 600px;
}

.inside-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  .inside-page-title {
    flex: 0 20px;
    height: 20px;
    padding: 14px 20px;
    border-bottom: 1px solid #e6e7ef;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #2a2b2f;
  }
  .inside-page-content {
    flex: 1 auto;
    padding: 14px 20px;
  }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

.el-dialog__header {
  background-color: #f2f3f4;
}

.el-step__title {
  font-size: 14px !important;
}

.el-step__head.is-finish {
  color: #3350CB !important;
}

.el-step__head.is-finish, .el-step__title.is-finish,
.el-step__head.is-process, .el-step__title.is-process {
  color: #3350CB !important;
  border-color: #3350CB !important;
}

.el-step__head.is-wait, .el-step__title.is-wait {
  color: #61636E !important;
  border-color: #61636E !important;
}

.el-ime-mode-disabled {
  input {
    ime-mode: disabled;
  }
}

</style>
