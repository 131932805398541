<template>
  <div class="login">
    <div class="left">
      <div class="register-desc">
        Copyright ® 2022-2032 <br/> 湖南数字法图科技有限公司 湘ICP备2023009052号-1
      </div>
    </div>
    <div class="right">
      <div class="header">
          <img  class="img" :src="logo"/>
          <span class="split"></span>
          <span class="title">用科技助力法治建设</span>
      </div>
      <div class="login-container" @keypress.enter="handlerEnter">
        <div class="form" v-show="loginStatus === 1">
          <div class="title">
            <template v-if="!currentBind">
              登录
            </template>
            <template v-if="currentBind">
              <i class="el-icon-arrow-left" @click="currentBind=false;currentUnionid=null"/>
              用户绑定
            </template>
          </div>
          <div class="tabs">
            <div class="tab-item" :class="{ active: active === 'password'}" @click="changeActive('password')">帐号密码{{currentBind? '绑定' : '登录' }}</div>
            <div class="tab-item" :class="{ active: active === 'code'}" @click="changeActive('code')">手机号{{currentBind? '绑定' : '登录' }}</div>
            <div v-if="!currentBind" class="tab-item" :class="{ active: active === 'scan'}" @click="changeActive('scan')">微信扫码登录</div>
          </div>
          <el-form v-show="active === 'password'" ref="passwordForm" :model="passwordForm" :rules="passwordFormRules" class="login-form">
            <el-form-item prop="phone">
              <el-input v-model="passwordForm.phone" size="nomarl" placeholder="请输入帐号手机号">
                <template slot="prepend">
                  <i class="icon iconfont icon-dengluzhanghaoicon" ></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password"  v-model="passwordForm.password" size="nomarl" placeholder="请输入登录密码">
                <template slot="prepend">
                  <i class="icon iconfont icon-denglumimaicon" ></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input v-model="passwordForm.code" class="el-ime-mode-disabled" size="nomarl" style="width: 270px;ime-mode:disabled" placeholder="请输入图像验证码">
                <template slot="prepend">
                  <i class="icon iconfont icon-tuxingmaicon" ></i>
                </template>
              </el-input>
              <div class="vaild-code" @click="refreshCode" ref="codeImgeEl"></div>
            </el-form-item>
            <el-form-item prop="agreen" class="form-agreen">
              <el-tooltip :value="!agreen && active === 'password' && loginStatus === 1" :manual="true" popper-class="login-agreen-tooltip" class="item" effect="dark" content="请阅读并勾选用户协议" placement="top">
                <el-checkbox v-model="agreen" style="margin-right: 10px;">
                </el-checkbox>
              </el-tooltip>
                <span>我已阅读并同意</span>
                  <span style="color:#5c8afa;cursor:pointer" @click="gotoUserProtocol">《用户协议》</span>
                  <span>和</span>
                  <span style="color:#5c8afa;cursor:pointer" @click="gotoPrivacyProtocol">《隐私政策》</span>
            </el-form-item>
            <el-form-item>
               <el-button type="primary" class="login-btn" ref="passwordLoginBtn" :disabled="passwordLoginBtnDisabled" @click="passwordLogin">登录</el-button>
            </el-form-item>
            <el-form-item>
              <div class="user-registor">
                <div class="registor">
                  <span>还没帐号？</span>
                  <span style="color:#5c8afa;cursor:pointer;" @click="gotoUserRegister">立即注册</span>
                </div>
                <div class="find">
                  <span style="color:#5c8afa;cursor:pointer;" @click="gotoFindPassword">找回密码</span>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <el-form v-show="active === 'code'" ref="codeForm" :model="codeForm" :rules="codeFormRules" class="login-form">
            <el-form-item prop="phone">
              <el-input v-model="codeForm.phone" size="nomarl" placeholder="请输入手机号">
                <template slot="prepend">
                  <span>+86</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input v-model="codeForm.code"  size="nomarl" placeholder="请输入短信验证码">
                <template slot="prepend">
                  <i class="icon iconfont icon-duanxinyanzhengmaicon"/>
                </template>
                <el-button :disabled="sendBtnDisabled['login']" slot="append" type="text" @click="sendCode(codeForm.phone, 'login')">{{sendBtnText['login']}}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item class="form-agreen">
              <el-tooltip :value="!agreen && active === 'code' && loginStatus === 1" :manual="true" popper-class="login-agreen-tooltip" class="item" effect="dark" content="请阅读并勾选用户协议" placement="top">
                <el-checkbox v-model="agreen" style="margin-right: 10px;">
                </el-checkbox>
              </el-tooltip>
              <span>我已阅读并同意</span>
                  <span style="color:#5c8afa;cursor:pointer" @click="gotoUserProtocol">《用户协议》</span>
                  <span>和</span>
                  <span style="color:#5c8afa;cursor:pointer" @click="gotoPrivacyProtocol">《隐私政策》</span>
            </el-form-item>
            <el-form-item>
               <el-button type="primary" class="login-btn" :disabled="codeLoginBtnDisabled" @click="codeLogin">登录</el-button>
            </el-form-item>
          </el-form>
          <el-form v-show="active === 'scan' && !currentBind" ref="scanForm" :model="sacnForm" :rules="{}" class="login-form">
            <div id="wx_login_container" style="text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;position: relative;">
              <img style="width: 280px;margin-top: 15px;border: 1px solid #e2e2e2;" :src="loginQrcodeUrl"/>
              <span style="font-size: 14px;color:rgba(97, 99, 110, 0.98);margin-top: 10px;">使用微信扫一扫登录</span>
              <span style="font-size: 14px;color:rgba(97, 99, 110, 0.98);margin-top: 5px;">“法图灵”</span>
              <div style="width: 280px;position: absolute;height: 280px;top: 15px;background-color: #000000AA;display: flex;flex-direction: column;justify-content: center;color: #ffff;cursor:pointer;" v-if="!qrCodeInv" @click="refreshWxQrcode">
                 <i style="font-size: 50px;" class="el-icon-refresh"></i>
                 <span>二维码已过期，点击重新刷新</span>
              </div>
            </div>
          </el-form>
        </div>
        <div class="form" v-show="loginStatus === 2">
          <div class="title" style="cursor: pointer;" @click="backFindPassword"><i class="el-icon-arrow-left"/>忘记密码</div>
          <div style="margin-bottom: 20px;">
            <el-steps :active="forgetPasswordActive" align-center>
              <el-step title="验证身份"></el-step>
              <el-step title="重置密码"></el-step>
              <el-step title="完成"></el-step>
            </el-steps>
          </div>
          <div>
            <el-form class="login-form" ref="forgetPasswordForm" :model="forgetPasswordForm" :rules="forgetPasswordFormRules">
              <el-form-item v-show="forgetPasswordActive === 0" prop="phone">
                <el-input v-model="forgetPasswordForm.phone" size="nomarl" placeholder="请输入手机号">
                  <template slot="prepend">
                    <span>+86</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item ref="forgetPasswordCodeItem" v-show="forgetPasswordActive === 0" prop="code">
                <el-input v-model="forgetPasswordForm.code"  size="nomarl" placeholder="请输入短信验证码">
                  <template slot="prepend">
                    <i class="icon iconfont icon-duanxinyanzhengmaicon"/>
                  </template>
                  <el-button :disabled="sendBtnDisabled['password']" slot="append" type="text" @click="sendCode(forgetPasswordForm.phone, 'password')">{{sendBtnText['password']}}</el-button>
                </el-input>
              </el-form-item>
              <el-form-item v-show="forgetPasswordActive === 1" prop="password">
                <el-popover
                  placement="bottom"
                  width="380"
                  trigger="manual"
                  :visible-arrow="false"
                  :value="passwordStrengthVisable"
                  popper-class="forget-password-strength-popup"
                  >
                  <div class="forget-password-strength">
                    <div class="progress" :class="'f' + passwordStrength">
                      <div class="item f1">
                        <div class="color"></div>
                        <div class="text">弱</div>
                      </div>
                      <div class="item f2">
                        <div class="color"></div>
                        <div class="text">一般</div>
                      </div>
                      <div class="item f3">
                        <div class="color"></div>
                        <div class="text">强</div>
                      </div>
                    </div>
                    <div class="strength-text">
                      6-20位字符，至少包含大写字母、小写字母、符号、数字中2类
                    </div>
                  </div>
                  <el-input slot="reference" type="password" @blur="passwordStrengthVisable = false"  @input="changeForgetPassword"  v-model="forgetPasswordForm.password" size="nomarl" placeholder="请输入新密码">
                    <template slot="prepend">
                      <i class="icon iconfont icon-denglumimaicon"/>
                    </template>
                  </el-input>
                </el-popover>
              </el-form-item>
              <el-form-item v-show="forgetPasswordActive === 1" prop="newPassowrd">
                <el-input type="password"  v-model="forgetPasswordForm.newPassowrd" size="nomarl" placeholder="请再次输入新密码">
                  <template slot="prepend">
                    <i class="icon iconfont icon-denglumimaicon"/>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item v-show="forgetPasswordActive === 2">
                <div>
                  <div style="text-align: center;">
                    <i class="iconfont icon-chenggongtishiicon" style="font-size: 32px;color: #00bfa0;"></i>
                  </div>
                  <div style="text-align: center;font-size: 18px;font-weight: 500;">您的密码已重置成功!</div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :disabled="forgetBtnDisabled" class="login-btn" @click="forgetPasswordActiveChange(forgetPasswordActive + 1)">{{forgetPasswordBtnText}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="form" v-show="loginStatus === 3">
          <div class="title" style="cursor: pointer;" @click="backUserRegister"><i class="el-icon-arrow-left"/>用户注册</div>
          <div>
            <el-form class="login-form" ref="registerForm" :model="registerForm" :rules="registerFormRules">
              <el-form-item prop="type">
                <el-popover
                  placement="bottom"
                  width="400"
                  trigger="click"
                  ref="registerTypePopup"
                  popper-class="user-register-popup"
                  >
                  <div class="menu">
                    <div class="menu-item" @click="changeRegisterType('个人帐号')">个人帐号</div>
                    <div class="menu-item" @click="changeRegisterType('企业帐号')">企业帐号</div>
                  </div>
                  <el-input class="user-registry-type-select" readonly slot="reference" v-model="registerForm.type" size="nomarl" placeholder="请选择帐号类型">
                    <template slot="prepend">
                      <i class="icon iconfont icon-dengluzhanghaoicon"/>
                    </template>
                    <template slot="append">
                      <i class="el-icon-arrow-down"/>
                    </template>
                  </el-input>
                </el-popover>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input v-model="registerForm.phone" size="nomarl" placeholder="请输入手机号码">
                  <template slot="prepend">
                    <span>+86</span>
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item  prop="code">
                <el-input v-model="registerForm.code"  size="nomarl" placeholder="请输入短信验证码">
                  <template slot="prepend">
                    <i class="icon iconfont icon-duanxinyanzhengmaicon"/>
                  </template>
                  <el-button :disabled="sendBtnDisabled['register']" slot="append" type="text" @click="sendCode(registerForm.phone, 'register', true)">{{sendBtnText['register']}}</el-button>
                </el-input>
              </el-form-item>

              <el-form-item prop="trusName">
                <el-input v-model="registerForm.trusName" size="nomarl" placeholder="输入真实姓名有利于获得更好的体验和服务">
                  <template slot="prepend">
                    <i class="icon iconfont icon-zhenshixingmingicon"/>
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="companyName" v-show="registerForm.type === '企业帐号'">
                <el-input v-model="registerForm.companyName" size="nomarl" placeholder="请输入公司名称">
                  <template slot="prepend">
                    <i class="icon iconfont icon-qiyemingchengicon"/>
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="imgCode">
                <el-input v-model="registerForm.imgCode"  size="nomarl" style="width: 270px" placeholder="请输入图像验证码">
                  <template slot="prepend">
                    <i class="icon iconfont icon-tuxingmaicon"/>
                  </template>
                </el-input>
                <div class="vaild-code"  @click="refreshCode" ref="codeImgeEl1"></div>
              </el-form-item>

              <el-form-item prop="agreen" class="form-agreen">
                <el-tooltip ref="registerTip" :value="!registerForm.agreen && loginStatus === 3" :manual="true" popper-class="login-agreen-tooltip" class="item" effect="dark" content="请阅读并勾选用户协议" placement="top">
                  <el-checkbox v-model="registerForm.agreen" style="margin-right: 10px">
                  </el-checkbox>
                </el-tooltip>
                <span>我已阅读并同意</span>
                <span style="color:#5c8afa;cursor:pointer" @click="gotoUserProtocol">《用户协议》</span>
                  <span>和</span>
                  <span style="color:#5c8afa;cursor:pointer" @click="gotoPrivacyProtocol">《隐私政策》</span>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :disabled="registerBtnDisabled"  class="login-btn" @click="registerUser">注册</el-button>
              </el-form-item>
              <el-form-item>
                <div>
                  <span>已有帐号？</span>
                  <span style="cursor:pointer; color:rgb(92, 138, 250);" @click="backUserRegister">登录</span>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="form center" v-show="loginStatus === 4">
          <div>
            <div class="register-finish-item tip">
              <i class="el-icon-circle-check"/>
              <span>注册已完成</span>
            </div>
            <div class="register-finish-item">
              <span>为防范支付风险，保护您的账号安全，请完成实名认证</span>
            </div>
            <div class="register-finish-item">
              <el-button class="login-btn" type="primary"  @click="backUserRegister">返回登录页面</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Server from '@/common/apis'
import logo from '@/web/assets/logo.png'
import { regexp, getUrlQueryString, getCookie } from '@ft/common-library'

const regs = [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/]
const getMatchNum = (password) => {
  return regs.filter(row => row.test(password)).length
}
export default {
  data () {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!regexp.phone.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const validatePhoneExist = async (rule, value, callback) => {
      if (this.registerForm.type === '个人帐号') {
        const res = await this.$apis.validPhone(value)
        if (res.data.result) {
          callback(new Error('用户手机已存在，请更换手机号'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateOrgaNameExist = (rule, value, callback) => {
      if (this.registerForm.type === '企业帐号') {
        if (!value) {
          callback(new Error('请输入公司名称'))
        } else {
          this.$apis.validOrgaName(value).then((res) => {
            if (res.data.result) {
              callback(new Error('该企业已注册帐号'))
            } else {
              callback()
            }
          })
        }
      } else {
        callback()
      }
    }

    const validateAgreen = (rule, value, callback) => {
      if (!value) {
        callback(new Error(' '))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else if (value.length < 6 || value.length > 20) {
        callback(new Error('密码由6-20位字符组成，区分大小写 '))
      } else if (getMatchNum(value) < 2) {
        callback(new Error('密码至少包含大写字母、小写字母、数字、字母中的2类'))
      } else {
        callback()
      }
    }
    const validateRePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入新密码'))
      } else if (this.forgetPasswordForm.password !== value) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }

    return {
      inv: null,
      currentBind: false,
      currentUnionid: null,
      currentOpenid: null,
      passwordStrengthVisable: false,
      forgetPasswordActive: 0,
      loginStatus: 1,
      channelId: getCookie('channel_id') ? parseInt(getCookie('channel_id')) : null,
      channelMemberId: getCookie('channel_member_id') ? parseInt(getCookie('channel_member_id')) : null,
      sendBtnText: {
        password: '获取验证码',
        login: '获取验证码',
        register: '获取验证码'
      },
      forgetPasswordBtnText: '下一步',
      active: 'password',
      sendBtnDisabled: {
        password: false,
        login: false,
        register: false
      },
      logo,
      passwordForm: {
        phone: '',
        password: '',
        code: '',
        agreen: false
      },
      text: '',
      passwordFormRules: {
        phone: [
          { required: true, message: '请输入登录手机号码或帐号' },
          { validator: validatePhone }
        ],
        password: [
          { required: true, message: '请输入登录密码' }
        ],
        code: [
          { required: true, message: '请输入验证码' }
        ]
      },
      codeForm: {
        phone: '',
        code: '',
        agreen: false
      },
      codeFormRules: {
        phone: [
          { required: true, message: '请输入登录手机号码' },
          { validator: validatePhone }
        ],
        code: [
          { required: true, message: '请输入验证码' }
        ]
      },
      forgetPasswordForm: {
        phone: '',
        code: '',
        password: '',
        newPassowrd: ''
      },
      forgetPasswordFormRules: {
        phone: [
          { required: true, message: '请输入登录手机号码' },
          { validator: validatePhone }
        ],
        code: [
          { required: true, message: '请输入验证码' }
        ],
        password: [
          { required: true, message: '请输入新密码' },
          { validator: validatePassword }
        ],
        newPassowrd: [
          { required: true, message: '请再次输入新密码' },
          { validator: validateRePassword, trigger: 'blur' }
        ]
      },
      registerForm: {
        type: '个人帐号', // 个人帐号   企业帐号
        phone: '', // 手机号
        code: '',
        trusName: '',
        companyName: '',
        imgCode: '',
        agreen: false
      },
      registerFormRules: {
        type: [{ required: true, message: '请选择注册类型' }],
        phone: [
          { required: true, message: '请输入手机号码' },
          { validator: validatePhone },
          { validator: validatePhoneExist, trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入短信验证密码' }],
        trusName: [{ required: true, message: '请输入真实姓名' }],
        companyName: [{ validator: validateOrgaNameExist, trigger: 'blur' }],
        agreen: [
          { validator: validateAgreen }
        ]
      },
      sacnForm: {},
      loginQrcodeUrl: null,
      qrCodeInv: null
    }
  },
  computed: {
    agreen: {
      get () {
        return this.passwordForm.agreen
      },
      set (value) {
        this.passwordForm.agreen = value
        this.codeForm.agreen = value
      }
    },
    passwordStrength () {
      const password = this.forgetPasswordForm.password
      if (password && password.length >= 9 && getMatchNum(password) > 2) {
        return 3
      } else if (password && password.length >= 9 && getMatchNum(password) === 2) {
        return 2
      } else if (password && password.length <= 8 && password.length >= 6 && getMatchNum(password) >= 2) {
        return 1
      } else {
        return 0
      }
    },
    passwordLoginBtnDisabled () {
      if (this.loginStatus === 1 && (!this.passwordForm.phone || !this.passwordForm.password || !this.passwordForm.code || !this.agreen)) {
        return true
      } else {
        return false
      }
    },
    codeLoginBtnDisabled () {
      if (this.loginStatus === 1 && (!this.codeForm.phone || !this.codeForm.code || !this.agreen)) {
        return true
      } else {
        return false
      }
    },
    registerBtnDisabled () {
      if (this.loginStatus === 3 && (!this.registerForm.phone || !this.registerForm.code || !this.registerForm.trusName || (this.registerForm.type === '企业帐号' && !this.registerForm.companyName) || !this.registerForm.imgCode || !this.registerForm.agreen)) {
        return true
      } else {
        return false
      }
    },
    forgetBtnDisabled () {
      if (this.forgetPasswordActive === 0) {
        return (!this.forgetPasswordForm.phone || !this.forgetPasswordForm.code)
      } else if (this.forgetPasswordActive === 1) {
        return (!this.forgetPasswordForm.password || !this.forgetPasswordForm.newPassowrd)
      } else {
        return false
      }
    }
  },
  mounted () {
    this.$apis = new Server(this.$axios, this.$message.warning)
    this.refreshCode()
  },
  watch: {
    '$route.query': {
      handler () {
        if (this.$route.query?.type === 'register') {
          this.loginStatus = 3
        } else {
          this.loginStatus = 1
        }
        if (this.$route.query?.type === 'scan') {
          this.changeActive('scan')
        }
      },
      immediate: true,
      deep: true
    },
    active: {
      handler (val) {
        if (val === 'scan') {
          this.refreshWxQrcode()
        } else {
          clearInterval(this.qrCodeInv)
          this.qrCodeInv = null
        }
      },
      immediate: true
    }
  },
  methods: {
    handlerEnter () {
      if (this.active === 'password') {
        this.passwordLogin()
      } else if (this.active === 'code') {
        this.codeLogin()
      }
    },
    gotoUserProtocol () {
      window.open('/user_protocol.html', '_blank')
    },
    gotoPrivacyProtocol () {
      window.open('/privacy_protocol.html', '_blank')
    },
    changeForgetPassword () {
      this.passwordStrengthVisable = true
      if (this.inv) clearTimeout(this.inv)
      this.inv = setTimeout(() => {
        this.passwordStrengthVisable = false
      }, 2000)
    },
    async changeActive (active) {
      this.active = active
      this.$refs[active + 'Form'].clearValidate()
    },
    async refreshCode () {
      const res = await this.$apis.getCode()
      this.$refs.codeImgeEl.innerHTML = res.data.result.svg
      const svg = this.$refs.codeImgeEl.querySelector('svg')
      svg.setAttribute('height', '36')
      svg.setAttribute('width', '120')
      this.$refs.codeImgeEl1.innerHTML = res.data.result.svg
      const svg1 = this.$refs.codeImgeEl1.querySelector('svg')
      svg1.setAttribute('height', '36')
      svg1.setAttribute('width', '120')
      this.text = res.data.result.text
      this.$refs.registerForm.clearValidate()
      this.$refs.forgetPasswordForm.clearValidate()
      this.$refs.passwordForm.clearValidate()
      this.$refs.codeForm.clearValidate()
    },
    async refreshWxQrcode () {
      // const obj = new window.WxLogin({
      //   self_redirect: true, // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
      //   id: 'wx_login_container', // 第三方页面显示二维码的容器id
      //   appid: 'wx41475734f3a8b735', // 应用唯一标识，在微信开放平台提交应用审核通过后获得
      //   scope: 'snsapi_login', // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
      //   redirect_uri: encodeURIComponent(`${window.location.protocol}//sso.ftling.com/#/wechat_qrcode_login`), // 重定向地址，需要进行UrlEncode
      //   state: 'wechat_scan', // 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
      //   style: 'black', // 提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
      //   href: '' // 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
      // })
      // console.log('wx_qrcode', obj)
      const res = await this.$apis.getWechatLoginQrcode()
      this.loginQrcodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${encodeURIComponent(res.data.result.ticket)}`
      const id = res.data.result.id
      this.qrCodeInv = setInterval(async () => {
        const res = await this.$apis.vaildWechatLoginQrcode(id)
        if (res.data.result.openid) {
          clearInterval(this.qrCodeInv)
          this.qrCodeInv = null
          // 扫码成功
          try {
            // 登录
            const resData = await this.$apis.wxLogin(res.data.result.openid)
            this.wxqrcode({ data: JSON.stringify({ action: 'select', data: { token: resData.data.access_token } }) })
          } catch (e) {
            // 登录失败进入绑定页面
            this.wxqrcode({ data: JSON.stringify({ action: 'bind', data: { unionid: res.data.result.openid, openid: res.data.result.fwopenid } }) })
          }
        } else if (res.data.result.expire) {
          clearInterval(this.qrCodeInv)
          this.qrCodeInv = null
          // 扫码超时
        }
      }, 2000)
    },
    getBackUrl () {
      let url = getUrlQueryString(window.location.href, 'url') || window.document.referrer
      if (!url || url.indexOf(window.location.host) >= 0) {
        url = '//mall.ftling.com'
      }
      return url
    },
    async passwordLogin () {
      if (!this.passwordLoginEntry) {
        try {
          this.passwordLoginEntry = true
          // this.$refs.passwordLoginBtn.$el.focus()
          const result = await this.$refs.passwordForm.validate()
          if (this.passwordForm.code !== this.text) {
            this.$message.error('验证码错误，请重新输入')
            return
          }
          if (!this.agreen) {
            try {
              setTimeout(() => {
                const el = document.querySelector('body > div.el-message-box__wrapper > div > div.el-message-box__btns > button.el-button.el-button--default.el-button--small.el-button--primary')
                if (el) {
                  el.focus()
                }
              }, 500)
              const messageVm = (
            <span>
              <span>我已阅读并同意</span>
              <span style="color: rgb(92, 138, 250); cursor: pointer;" onClick={this.gotoUserProtocol}>《用户协议》</span>
              <span>和</span>
              <span style="color: rgb(92, 138, 250); cursor: pointer;" onClick={this.gotoPrivacyProtocol}>《隐私政策》</span>
            </span>
              )
              await this.$msgbox({
                message: messageVm,
                title: '阅读并同意协议',
                confirmButtonText: '同意并继续',
                cancelButtonText: '取消',
                showCancelButton: true,
                autofoucs: true,
                type: 'warning'
              })
              this.agreen = true
            } catch (e) {
              this.passwordLoginEntry = false
              return
            }
          }
          if (result) {
            const res = await this.$apis.login(this.passwordForm.phone, this.passwordForm.password)
            if (this.currentBind && this.currentUnionid && this.currentOpenid) {
              // 绑定微信
              await this.$apis.bindWxUser(this.passwordForm.phone, this.currentUnionid, this.currentOpenid)
            }
            this._auth.tempToken = res.data.access_token
            this.$router.replace('/select')
          }
        } finally {
          this.refreshCode()
          this.passwordLoginEntry = false
        }
      }
    },
    async codeLogin () {
      if (!this.loginEntry) {
        try {
          this.loginEntry = true
          const result = await this.$refs.codeForm.validate()
          if (!this.agreen) {
            try {
              const messageVm = (
              <span>
                <span>我已阅读并同意</span>
                <span style="color: rgb(92, 138, 250); cursor: pointer;" onClick={this.gotoUserProtocol}>《用户协议》</span>
                <span>和</span>
                <span style="color: rgb(92, 138, 250); cursor: pointer;" onClick={this.gotoPrivacyProtocol}>《隐私政策》</span>
              </span>
              )
              setTimeout(() => {
                const el = document.querySelector('body > div.el-message-box__wrapper > div > div.el-message-box__btns > button.el-button.el-button--default.el-button--small.el-button--primary')
                if (el) {
                  el.focus()
                }
              }, 500)
              await this.$msgbox({
                message: messageVm,
                title: '阅读并同意协议',
                confirmButtonText: '同意并继续',
                cancelButtonText: '取消',
                showCancelButton: true,
                type: 'warning'
              })
              this.agreen = true
            } catch (e) {
              return
            }
          }
          if (result) {
            const res = await this.$apis.smsLogin(this.codeForm.phone, this.codeForm.code)
            if (this.currentBind && this.currentUnionid && this.currentOpenid) {
              // 绑定微信
              await this.$apis.bindWxUser(this.codeForm.phone, this.currentUnionid, this.currentOpenid)
            }
            this._auth.tempToken = res.data.access_token
            this.$router.replace('/select')
          }
        } finally {
          this.loginEntry = false
        }
      }
    },
    async sendCode (phone, bizType, valid) {
      if (!regexp.phone.test(phone)) {
        this.$message({
          message: '请输入正确的手机号码',
          type: 'error'
        })
        return
      }
      // 验证手机是否重复
      if (valid && this.registerForm.type === '个人帐号') {
        const res = await this.$apis.validPhone(phone)
        if (res.data.result) {
          this.$message({
            message: '用户手机已存在，请更换手机号',
            type: 'error'
          })
          return
        }
      }
      if (this.sendBtnDisabled[bizType]) return
      await this.$refs.codeForm.validateField('phone')
      await this.$apis.sendCode(phone, bizType)
      this.$message({
        message: '短信验证码已发送成功',
        type: 'success'
      })
      this.sendBtnDisabled[bizType] = true
      let n = 60
      const inv = setInterval(() => {
        this.sendBtnText[bizType] = `${n} 秒`
        n--
        if (n === 0) {
          this.sendBtnText[bizType] = '获取验证码'
          this.sendBtnDisabled[bizType] = false
          clearInterval(inv)
        }
      }, 1000)
    },
    gotoFindPassword () {
      this.loginStatus = 2
      this.forgetPasswordForm.phone = ''
      this.forgetPasswordForm.code = ''
      this.forgetPasswordForm.password = ''
      this.forgetPasswordForm.newPassowrd = ''
      this.forgetPasswordActive = 0
      this.forgetPasswordBtnText = '下一步'
      this.agreen = true
    },
    gotoUserRegister () {
      this.refreshCode()
      this.loginStatus = 3
    },
    backUserRegister () {
      this.registerForm.agreen = false
      this.registerForm.code = ''
      this.registerForm.companyName = ''
      this.registerForm.imgCode = ''
      this.registerForm.phone = ''
      this.registerForm.type = '个人帐户'
      this.refreshCode()
      this.loginStatus = 1
    },
    backFindPassword () {
      this.passwordForm.agreen = false
      this.passwordForm.code = ''
      this.passwordForm.password = ''
      this.passwordForm.phone = ''
      this.refreshCode()
      this.loginStatus = 1
    },
    async forgetPasswordActiveChange (active) {
      if (active === 1) {
        await new Promise((resolve, reject) => {
          this.$refs.forgetPasswordForm.validateField(['phone', 'code'], (error) => {
            if (error) {
              reject(error)
            } else {
              resolve(0)
            }
          })
        })
        try {
          const res = await this.$apis.vaildCode(this.forgetPasswordForm.phone, this.forgetPasswordForm.code, 'password')
          this.token = res.data.result
          this.$refs.forgetPasswordCodeItem.error = null
        } catch (error) {
          this.$refs.forgetPasswordCodeItem.error = '验证码无效'
          return
        }
      }
      if (active === 2) {
        await new Promise((resolve, reject) => {
          this.$refs.forgetPasswordForm.validateField(['password', 'newPassword'], (error) => {
            if (error) {
              reject(error)
            } else {
              resolve(0)
            }
          })
        })
        await this.$apis.modifyPassword(this.forgetPasswordForm.phone, this.forgetPasswordForm.password, this.token)
        this.forgetPasswordBtnText = '完成'
      }
      if (active === 3) {
        this.forgetPasswordActive = 1
        this.backFindPassword()
      }
      this.forgetPasswordActive = active
    },
    changeRegisterType (type) {
      this.registerForm.type = type
      this.$refs.registerTypePopup.doClose()
      if (this.$refs.registerTip.value) {
        this.$nextTick(() => {
          this.$refs.registerTip.showPopper = true
        })
        this.$refs.registerTip.showPopper = false
      }
    },
    async registerUser () {
      await this.$refs.registerForm.validate()
      switch (this.registerForm.type) {
        case '个人帐号': {
          const data = {
            phone: this.registerForm.phone,
            code: this.registerForm.code,
            trusName: this.registerForm.trusName,
            channelId: this.channelId,
            channelMemberId: this.channelMemberId
          }
          await this.$apis.registerUser(data)
          this.loginStatus = 4
          break
        }
        case '企业帐号': {
          const data = {
            phone: this.registerForm.phone,
            code: this.registerForm.code,
            trusName: this.registerForm.trusName,
            companyName: this.registerForm.companyName,
            channelId: this.channelId,
            channelMemberId: this.channelMemberId
          }
          await this.$apis.registerOrga(data)
          this.loginStatus = 4
          break
        }
      }
    },
    async wxqrcode (event) {
      if (!event.data || typeof event.data !== 'string') {
        return
      }
      const data = JSON.parse(event.data)
      switch (data.action) {
        case 'bind':
        {
          const unionid = data.data.unionid
          const openid = data.data.openid
          this.active = 'password'
          this.currentUnionid = unionid
          this.currentOpenid = openid
          this.currentBind = true
          break
        }
        case 'select':
        {
          const token = data.data.token
          this._auth.tempToken = token
          this.$router.replace('/select')
          break
        }
        case 'reset':
        {
          this.refreshWxQrcode()
          break
        }
      }
    }

  },
  created () {
    window.addEventListener('message', this.wxqrcode)
  },
  beforeDestroy () {
    if (this.qrCodeInv) {
      clearInterval(this.qrCodeInv)
    }
    window.removeEventListener('message', this.wxqrcode)
  }
}
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: #4660d2;
  display: flex;
  flex-direction: row;
  .left {
    flex: 1 auto;
    background-image: url(@/web/assets/images/login-background.png);
    background-position: top left;
    background-size:  cover;
    .register-desc {
      font-size: 14px;
      text-align: right;
      margin-right: 20px;
      margin-top: 40px;
      color: #8790ba;
    }
  }
  .right {
    flex: 0 0 600px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    .header {
      padding: 36px;
      .img {
        height: 42px;
        vertical-align: middle;
      }
      .split {
        display: inline-block;
        height: 36px;
        width: 1px;
        background-color: #e6e7ef;
        line-height: 48px;
        vertical-align: super;
        margin: 0 20px;
        vertical-align: middle;
      }
      .title {
        vertical-align: middle;
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        color: #2a2b2f;
      }
    }
    .login-container {
      flex: 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .form {
        width: 400px;
        height: 550px;
        margin: 0 10px;
        &.center {
          display: flex;
          flex-direction: row;
          justify-items: center;
          align-items: center;
        }
        .title {
          height: 40px;
          font-size: 28px;
          font-weight: 600;
          text-align: left;
          color: rgba(42,43,47,0.98);
          line-height: 40px;
          margin-bottom: 42px;
        }
        .tabs {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
          .tab-item {
            cursor: pointer;
            padding-bottom: 5px;
            width: 100px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(97,99,110,0.98);
            line-height: 22px;
            border-bottom: 2px solid transparent;
            margin-right: 30px;
            text-align: center;
            &.active {
              font-weight: 600;
              color: rgba(42,43,47,0.98);
              border-bottom: 2px solid #3350cb;
            }
          }
        }
        .login-form {
          .vaild-code {
            display: inline-block;
            height: 40px;
            cursor: pointer;
            vertical-align: middle;
            background-color: #E6E7EF;
            width: 120px;
            margin-left: 10px;
            text-align: center;
          }

          .login-btn {
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            width: 100%;
            height: 44px;
            border-radius: 4px;
          }
          .form-agreen {
            padding-top: 20px;
            margin-bottom: 8px;
            /deep/ .el-form-item__content {
               line-height: 24px;
            }
          }
        }
        /deep/ .el-input-group__prepend{
          background-color: #fff;
          width: 27px;
          padding: 0 10px;
          text-align: center;
          line-height: 36px;
        }
        /deep/ .el-input-group__append {
          background-color: #fff;
          color: #3350cb;
          font-weight: 400;
          width: 88px;
          text-align: center;
        }
        .user-registor {
          display: flex;
          flex-direction: row;
          .registor {
            flex: 1 50%''
          }
          .find {
            flex: 1 50%;
            text-align: right;
          }
        }
        .register-finish-item {
          text-align: center;
          margin-bottom: 20px;
          &.tip {
            font-size: 28px;
            i {
              color: #4BBCA1;
              margin-right: 10px;
              font-size: 28px;
            }

          }
          .login-btn {
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            width: 60%;
            height: 44px;
            border-radius: 4px;
          }
        }
      }

    }
  }
}
</style>
<style lang="less">
.forget-password-strength-popup {
  margin-top: 3px !important;
}
.forget-password-strength {
  height: 90px;
  .progress {
    display: flex;
    flex-direction: row;
    .item {
      flex: 1 33%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 55px;
      .color {
        margin: 0 5px 5px 5px;
        height: 6px;
        background: #e6e7ef;
        border-radius: 1px;
      }
      .text {
        flex: 1 auto;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #5a5c65;
        line-height: 22px;
      }
    }
    &.f0 {
      .f1 {
        .text {
          visibility: hidden;
        }
      }
      .f2 {
        .text {
          visibility: hidden;
        }
      }
      .f3 {
        .text {
          visibility: hidden;
        }
      }
    }
    &.f1 {
      .f1 {
        .text {
          visibility: visible;
          color: #ff9640;
        }
        .color {
          background-color: #ff9640;
        }
      }
      .f2 {
        .text {
          visibility: hidden;
        }
      }
      .f3 {
        .text {
          visibility: hidden;
        }
      }
    }
    &.f2 {
      .f1 {
        .text {
          visibility: hidden;
          color: #ff9640;
        }
        .color {
          background-color: #ff9640;
        }
      }
      .f2 {
        .text {
          visibility: visible;
          color: #ff9640;
        }
        .color {
          background-color: #ff9640;
        }
      }
      .f3 {
        .text {
          visibility: hidden;
        }
      }
    }

    &.f3 {
      .f1 {
        .text {
          visibility: hidden;
          color: #4bbca1;
        }
        .color {
          background-color: #4bbca1;
        }
      }
      .f2 {
        .text {
          visibility: hidden;
          color: #4bbca1;
        }
        .color {
          background-color: #4bbca1;
        }
      }
      .f3 {
        .text {
          visibility: visible;
        }
        .color {
          background-color: #4bbca1;
        }
      }
    }

  }
  .strength-text {
    height: 22px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #5a5c65;
    line-height: 22px;
  }
}

.user-register-popup {
  padding: 10px 0px !important;
  width: 300px !important;
  .menu {
    .menu-item {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      &:hover {
        cursor: pointer;
        background-color: #e6e7ef;
      }

    }
  }
}

</style>
<style lang="less">
.user-registry-type-select {
  input {
    cursor:pointer !important;
    // border-right: 0 !important;
  }
  .el-input-group__append {
    padding: 0;
    width: 40px !important;
  }
}
.login-agreen-tooltip {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  background-color: #303133ab !important;
  .popper__arrow {
     border-top-color: #30313300 !important;
    &::after {
      border-top-color: #303133ab !important;
    }
  }
}
</style>
