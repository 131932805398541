import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import ElementUI from 'element-ui'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import filters from '@/web/utils/filter'
import '@/web/assets/css/theme/index.css'
import {
  resubmitInterceptor,
  errorInterceptor
} from '@ft/common-library'
import AuthUtils from '@/web/utils/usreinfo'

;(async () => {
  Vue.config.productionTip = false

  Vue.use(ElementUI, { size: 'small' })

  const domainString = location.hostname.split('.')
  const domain = [domainString[domainString.length - 2], domainString[domainString.length - 1]].join('.')
  const auth = new AuthUtils(domain)
  Vue.prototype._auth = auth

  // 请求时添加登录信息
  axios.interceptors.request.use(function (config) {
    config.headers.token = auth.tempToken || auth.getToken()
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  Object.keys(filters).forEach(row => {
    Vue.filter(row, filters[row])
  })

  window.global = new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')

  errorInterceptor(axios, {
    vm: window.global,
    needProcess: (error) => {
      if (error?.response?.data?.code === 400004) {
        router.push('/')
        return true
      }
      if (error?.response?.data?.code === 404001) {
        window.global.$message.error('无法调用接口，请添加接口权限: ' + error?.config?.method + ':' + error?.config?.url)
        return true
      }
      if (error?.response?.data?.status === 400 || error?.response?.data?.status === 404) {
        window.global.$message.error(error?.response?.data?.message)
        return true
      }
      if (error?.response?.data?.status === 500) {
        window.global.$message.error('服务器错误，请联系管理员')
        return true
      }
      return false
    },
    loginUrl: () => '/'
  })

  resubmitInterceptor(axios)
})()
