import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/web/webapp/login/index.vue'
import SelectView from '@/web/webapp/login/role-select.vue'
import QrcodeBackView from '@/web/webapp/login/qrcode.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LoginView
  },
  {
    path: '/select',
    component: SelectView
  },
  {
    path: '/wechat_qrcode_login',
    component: QrcodeBackView
  },
  {
    path: '/invite/:code',
    name: 'invite',
    component: () => import('@/web/webapp/invite.vue')
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
