import Api from './apis/index.js'
export default class Server extends Api {
  /**
   * 获取当前登录用户信息
   * @param {*} params
   * @returns
   */
  async getCurrentUser () {
    return await this.$http.get(`${Api.path.user}/user/current`)
  }

  async refreshToken (payload, headers) {
    return await this.$http.post('/api/refresh_token', { payload, headers })
  }

  async login (username, password) {
    return await this.$http.post('/api/login', { type: 'password', phone: username, password }, { params: { device: 'web' } })
  }

  async smsLogin (phone, code) {
    return await this.$http.post('/api/login', { type: 'sms', phone, code }, { params: { device: 'web' } })
  }

  async wxLogin (unionid) {
    return await this.$http.post('/api/login', { type: 'miniprogram', openid: unionid }, { params: { device: 'web' } })
  }

  async modifyPassword (phone, password, token) {
    return await this.$http.post(`${Api.path.user}/gateway/user/password`, { phone, password, token })
  }

  /**
   * 获取图型验证码
   * @returns
   */
  async getCode () {
    return await this.$http.get(`${Api.path.user}/common/code/image`)
  }

  /**
   * 发送短信验证码
   * @param {string} phone  手机号
   */
  async sendCode (phone, bizType) {
    return await this.$http.post(`${Api.path.user}/common/code/${bizType}`, { phone })
  }

  async vaildCode (phone, code, bizType) {
    return await this.$http.put(`${Api.path.user}/common/code/${bizType}`, { phone, code })
  }

  async validPhone (phone) {
    return await this.$http.post(`${Api.path.user}/common/validate/user/phone`, { phone })
  }

  async validOrgaName (name) {
    return await this.$http.post(`${Api.path.user}/common/validate/orga/name`, { name })
  }

  async registerUser (data) {
    return await this.$http.post(`${Api.path.user}/common/register/user`, data)
  }

  async registerOrga (data) {
    return await this.$http.post(`${Api.path.user}/common/register/orga`, data)
  }

  async getUserUnionid (code) {
    return await this.$http.post(`${Api.path.wechat}/info/users/open/unionid`, { code })
  }

  async bindWxUser (phone, unionid, openid) {
    return await this.$http.post(`${Api.path.user}/common/register/wechat/bind`, { phone, openid: unionid, fwopenid: openid })
  }

  async getWechatLoginQrcode () {
    return await this.$http.get('/wechat/v1/info/web/gzh/qrcode/temporary')
  }

  async vaildWechatLoginQrcode (id) {
    return await this.$http.get('/wechat/v1/info/web/gzh/qrcode/temporary/validate', { params: { id } })
  }
}
