import dayjs from 'dayjs'
export default {
  dateFormat: (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) return '-'
    return dayjs(date).format(format)
  },
  hidePhone: (phone) => {
    if (!phone) {
      return ''
    } else {
      return phone.substr(0, 3) + '****' + phone.substr(7)
    }
  },
  authStatus: (value) => {
    if (value === 0) {
      return '未认证'
    } else {
      return '已认证'
    }
  }

}
