<template>
  <div class="ft-frame-role-select">
    <div class="header">
      <div class="logo"></div>
    </div>
    <div class="main" v-loading="loading" element-loading-text="正在登录，请稍后...">
      <div class="content" v-if="!loading">
        <div class="title">请选择身份进入</div>
        <div class="split"></div>
        <div class="role-select" style="margin: 0 auto">
          <div class="items">
            <div
              class="item"
              v-for="(item, index) in selectList"
              :key="index"
              @click="select(item)"
            >
              <div class="name">
                {{ item.name }}
              </div>
              <div class="desc">
                <span>● 主体类型：</span>
                <span>
                  {{ item.type }}
                </span>
                <span class="auth f1" v-if="item.status === 0">
                  <i class="iconfont icon-weirenzhengicon" />
                  未认证
                </span>
                <span class="auth f2" v-if="item.status === 1">
                  <i class="iconfont icon-yirenzhengicon" />
                  已认证
                </span>
              </div>
              <div class="person-img"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Server from '@/common/apis'
import personImg from '@/web/assets/images/卡片图片2x.png'
import { getCookie, getUrlQueryString } from '@ft/common-library'
export default {
  data () {
    return {
      personImg,
      info: {},
      orgas: [],
      loading: false
    }
  },
  computed: {
    // 2 企业 3 政府机构 4 律师所 5 渠道商

    selectList () {
      const typeStrs = ['企业', '政府机构', '律师所', '渠道商']
      const selectList = [
        {
          id: this.info.id,
          type: '个人',
          typeId: 1,
          name: this.info.trusName,
          status: this.info.auth,
          entryable: true
        },
        ...this.orgas
          .map((row) => {
            return {
              id: row.id,
              type: typeStrs[row.type - 2],
              typeId: row.type,
              name: row.name,
              status: row.authStatus,
              entryable: row.entryable
            }
          })
          .filter((row) => row.id !== 0)
      ]
      return selectList
    }
  },
  async beforeMount () {
    this.loading = true
    try {
      this.$apis = new Server(this.$axios, this.$message.warning)
      const res = await this.$apis.getCurrentUser()
      const userInfo = res.data.result
      this.info = userInfo.info
      this.orgas = userInfo.orgas
      // 自动选择角色
      const backUrl = getUrlQueryString(window.location.href, 'url') || window.document.referrer
      if (backUrl.includes('mall.ftling.com') && getUrlQueryString(window.location.href, 'url')) {
        // 通过mall.ftling.com地址登录，则为个人登录，登录后无需跳转至身份选择页；
        await this.select(this.selectList[0])
      } else if (this.selectList.length === 1) {
        // 当没有企业时，直接登录个人端
        await this.select(this.selectList[0])
      } else {
        this.loading = false
      }
    } catch (e) {
      this.$router.replace('/')
    }
  },
  methods: {
    getBackUrl (typeId) {
      debugger
      let url = getUrlQueryString(window.location.href, 'url') || window.document.referrer
      // if (process.env.NODE_ENV === 'production') {
      if (!url || url.indexOf(window.location.host) >= 0) {
        // 无回跳地址的情况下
        if ([1].includes(typeId)) {
          // 个人回跳到 mall.ftling.com
          url = '//mall.ftling.com'
        } else if ([2].includes(typeId)) {
          // 企业回跳到 user.ftling.com
          url = '//user.ftling.com'
        } else if ([5].includes(typeId)) {
          // 渠道商回跳到渠道商管理系统
          url = '//coop.ftling.com'
        }
      } else {
        // 有回跳地址的情况下,
        if (url.includes('zx.ftling.com') || url.includes('pay.ftling.com')) {
          // 如果回跳是法图咨询或商品详情页时，直接到回跳
        } else if ([5].includes(typeId) && !url.includes('coop.ftling.com')) {
          // 选择渠道商身份，直接进入到渠道商
          url = '//coop.ftling.com'
        } else if ([2].includes(typeId) && !url.includes('user.ftling.com')) {
          // 选择企业身份，直接进入到用户平台
          url = '//user.ftling.com'
        } else if ([1].includes(typeId) && !url.includes('mall.ftling.com')) {
          // 如果选择是个人， 如果回跳地址不是 mall ，则跳转到 user.ftling.com
          url = '//user.ftling.com'
        }
      }
      // }
      return url
    },
    async select (row) {
      if (row.entryable) {
        const payload = {
          // 1 个人 2 企业 5 渠道商
          orgType: row.typeId,
          // 个人或企业 ID
          orgId: row.id
        }
        let headers = {
          ...payload,
          data_range: 1
        }
        const res2 = await this.$apis.getCurrentUser()
        const data = res2.data.result.members.filter(row => row.orga_id === payload.orgId)[0]
        if (data) {
          headers = { ...headers, data_range: data.data_range || 1 }
          if (data.dep_id) {
            headers.dep_id = data.dep_id
          }
        } else {
          headers = {
            ...headers,
            data_range: 1
          }
        }

        const resultPlayload = {
          orgType: 1,
          orgId: res2.data.result.userId,
          [payload.orgType === 5 ? 'coop' : 'user']: payload,
          mall: { orgType: 1, orgId: res2.data.result.userId }
        }

        const resultHeaders = {
          orgType: 1,
          orgId: res2.data.result.userId,
          data_range: 1,
          [payload.orgType === 5 ? 'coop' : 'user']: headers,
          mall: { orgType: 1, orgId: res2.data.result.userId, data_range: 1 }
        }

        const channelId = getCookie('channel_id') ? parseInt(getCookie('channel_id')) : null
        const channelMemberId = getCookie('channel_member_id') ? parseInt(getCookie('channel_member_id')) : null
        if (channelId && channelMemberId) {
          resultHeaders.channel_id = channelId
          resultHeaders.channel_member_id = channelMemberId
        }
        const res = await this.$apis.refreshToken(resultPlayload, resultHeaders)
        this._auth.setToken(res.data.access_token)
        window.location.replace(this.getBackUrl(row.typeId))
      } else {
        this.$message.error('你的账号已被停用，不可进入管理后台')
      }
    }
  }
}
</script>
<style lang="less">
.ft-frame-role-select {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f7f7fb;
  .header {
    background: linear-gradient(270deg, #3f61f0 1%, #3350cb);
    display: flex;
    flex-direction: row;
    padding: 5px;
    box-sizing: border-box;
    .logo {
      background-image: url(@/web/assets/logo-white.png);
      background-size: 100%;
      height: 48px;
      width: 126px;
    }
  }
  .main {
    flex: 1 auto;
    display: flex;
    flex-direction: row;
    height: 0;
    .content {
      flex: 1 auto;
      background-color: #f7f7fb;
      margin: 20px 80px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-color: #fff;
      .title {
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
      }
      .split {
        height: 1px;
        background-color: #e6e7ef;
        margin: 0px 5px;
      }
      .items {
        padding: 60px 160px;
        text-align: left;
        .item {
          width: 300px;
          height: 140px;
          background: linear-gradient(180deg, #f6f9ff 1%, #f2f6ff);
          border-radius: 4px;
          display: inline-block;
          flex-direction: column;
          cursor: pointer;
          padding: 0 20px;
          margin-right: 20px;
          margin-top: 20px;
          position: relative;
          box-sizing: border-box;
          .name {
            padding-top: 45px;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            color: #2a2b2f;
          }
          .desc {
            margin-top: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #9597a1;
            text-align: left;
            .auth {
              margin-left: 20px;
              background: #e6e7ef;
              border-radius: 2px;
              font-size: 10px;
              padding: 4px;
              .iconfont {
                font-size: 13px;
              }
              &.f1 {
                background: #e6e7ef;
              }
              &.f2 {
                background: #e2faf4;
                color: #4bbca1;
              }
            }
          }
          .person-img {
            position: absolute;
            background: url('@/web/assets/images/卡片图片2x.png');
            background-size: cover;
            bottom: 0;
            right: 0;
            height: 80px;
            width: 80px;
          }
        }
      }
      .role-select {
        width: 100%;
        overflow-y: auto;
      }
    }
  }
}
</style>
