<template>
  <div>

  </div>
</template>

<script>
import Server from '@/common/apis'
export default {
  beforeCreate () {
    this.$apis = new Server(this.$axios, this.$message.warning)
  },
  watch: {
    '$route.query': {
      handler (val) {
        if (val) {
          this.code = val.code
          this.login(this.code)
        }
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      code: ''
    }
  },
  methods: {
    async login (code) {
      const loadingInstance = this.$loading({
        lock: true,
        text: '正在登录...'
      })
      try {
        const res = await this.$apis.getUserUnionid(code)
        const { openid, unionid } = res.data.result
        try {
          const resData = await this.$apis.wxLogin(unionid)
          // 登录成功
          window.parent.postMessage(JSON.stringify({ action: 'select', data: { token: resData.data.access_token } }), '*')
        } catch (e) {
          window.parent.postMessage(JSON.stringify({ action: 'bind', data: { unionid: unionid, openid: openid } }), '*')
          // 登录失败，绑定用户
        }
      } finally {
        loadingInstance.close()
        window.parent.postMessage(JSON.stringify({ action: 'reset', data: { } }), '*')
        // window.history.back()
      }
    }
  }
}
</script>

<style>

</style>
